import React, { useState, createContext } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Grid, Sidebar, Segment } from "semantic-ui-react";
import Header from "./header";

import "semantic-ui-less/semantic.less";
import { SidebarWrapper } from "./sidebar";
import styled from "styled-components";
import { Footer } from "./footer.";
import { Spacer } from "ui-start";

export const AppContext = createContext();

const ContentWrapper = styled.div`
  .main-content {
    margin: 0 !important;
  }
`;

const Layout = ({ path, children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [footerHeight, setFooterHeight] = useState();
  const toggleSidebarVisible = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const data = useStaticQuery(graphql`
    query headerQueryAndSiteTitleQuery {
      allGoogleMenuPagesSheet {
        edges {
          node {
            position
            slug
            title
            subMenu01Slug
            subMenu01Title
            subMenu02Slug
            subMenu02Title
            subMenu03Slug
            subMenu03Title
            subMenu04Slug
            subMenu04Title
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const handleSetFooterHeight = (value) => setFooterHeight(value);

  const state = {
    sidebarVisible: sidebarVisible,
    setSidebarVisible: toggleSidebarVisible,
    menuData: data,
    handleSetFooterHeight,
  };

  const location = useLocation();
  const pathName = location.pathname;
  const modifiedPathName = pathName.replace(/[\/-]/g, " ").trim();

  const title =
    `${data.site.siteMetadata.title} - ${modifiedPathName}` ||
    data.site.siteMetadata.title;

  return (
    <AppContext.Provider value={state}>
      <Helmet
        title={title}
        meta={[
          { name: "description", content: "Sample" },
          { name: "keywords", content: "sample, something" },
        ]}
      />
      <Sidebar.Pushable as={Segment}>
        <SidebarWrapper />
        <Sidebar.Pusher dimmed={sidebarVisible} style={{ minHeight: "100vh" }}>
          <Header siteTitle={title} />
          <ContentWrapper>
            <Container fluid className="main-content">
              <Grid relaxed stackable>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  {children}
                </Grid.Column>
              </Grid>
            </Container>
          </ContentWrapper>
          <Spacer height={footerHeight + 32} />
          <Footer />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </AppContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
