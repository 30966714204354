import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Dropdown, Menu } from "semantic-ui-react";
import { AppContext } from "./layout";
import { device } from "../utilities/media_sizes";
import { primaryColors } from "../styles/colours";

const StyledMenu = styled(Menu)`
  background-color: ${primaryColors.paleGreen} !important;
  border: 0 !important;
  text-align: left;
  box-shadow: none !important;
  font-size: 20px !important;
  a {
    width: 100vw !important;
    /* color: white !important; */

    @media ${device.tablet} {
      width: 100% !important;
    }
  }
  & .ui.item.dropdown {
    width: fit-content;
    text-align: center;
    background: none !important;
    color: white !important;
  }
  & .ui.item.dropdown.active {
    padding-left: 0;
    padding-right: 0;
    margin-left: 24px;
  }
  & .dropdown.icon {
    display: none;
  }
  & .menu.transition.visible {
    position: initial;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    margin-top: 16px !important;
  }
  .item {
    opacity: ${({ hideMenu }) => (hideMenu ? ".5" : "1")};
  }
  .ui.item.dropdown,
  .item.link-items {
    opacity: 1;
    text-align: left !important;
  }
  .ui.vertical.menu .dropdown.active.item {
    margin-left: 24px !important;
  }
`;

const LinkedItem = ({ children, ...props }) => (
  <Menu.Item as={Link} activeClassName="active" {...props}>
    {children}
  </Menu.Item>
);
const DropdownLinkedItem = ({ children, ...props }) => (
  <Dropdown.Item
    as={Link}
    activeClassName="active"
    {...props}
    className="link-items"
  >
    {children}
  </Dropdown.Item>
);

export default function SliderMenuWrapper() {
  const [hideMenu, setHideMenu] = useState(false);
  const { menuData } = useContext(AppContext);
  const menuItems = menuData.allGoogleMenuPagesSheet.edges;

  return (
    <StyledMenu vertical fluid borderless hideMenu={hideMenu}>
      {menuItems.map(
        (
          {
            node: {
              slug,
              title,
              subMenu01Slug,
              subMenu01Title,
              subMenu02Slug,
              subMenu02Title,
              subMenu03Slug,
              subMenu03Title,
              subMenu04Slug,
              subMenu04Title,
              subMenu05Slug,
              subMenu05Title,
            },
          },
          i
        ) => (
          <div key={i}>
            {!subMenu01Slug ? (
              <LinkedItem to={`/${slug}`}>{title}</LinkedItem>
            ) : (
              <>
                <Dropdown
                  item
                  text={title}
                  onClick={() => setHideMenu(!hideMenu)}
                  closeOnBlur={false}
                >
                  <Dropdown.Menu>
                    {subMenu01Slug && (
                      <DropdownLinkedItem to={`/${subMenu01Slug}`}>
                        {subMenu01Title}
                      </DropdownLinkedItem>
                    )}
                    {subMenu02Slug && (
                      <DropdownLinkedItem to={`/${subMenu02Slug}`}>
                        {subMenu02Title}
                      </DropdownLinkedItem>
                    )}
                    {subMenu03Slug && (
                      <DropdownLinkedItem to={`/${subMenu03Slug}`}>
                        {subMenu03Title}
                      </DropdownLinkedItem>
                    )}
                    {subMenu04Slug && (
                      <DropdownLinkedItem to={`/${subMenu04Slug}`}>
                        {subMenu04Title}
                      </DropdownLinkedItem>
                    )}
                    {subMenu05Slug && (
                      <DropdownLinkedItem to={`/${subMenu05Slug}`}>
                        {subMenu05Title}
                      </DropdownLinkedItem>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </div>
        )
      )}
    </StyledMenu>
  );
}
