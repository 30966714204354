import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { AppContext } from "./layout";
import { Copy } from "ui-start";
import useMedia from "use-media";
import { primaryColors } from "../styles/colours";
import { Link } from "gatsby";

const Wrapper = styled.div`
  background: #5ac1d2;
  height: 60px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  & i {
    color: white;
    font-size: 30px;
    line-height: 30px;
  }
`;

const IconWrapper = styled.div`
  margin-top: 25px;
  cursor: pointer;
  i {
    color: #5ac1d2;
    font-size: 50px;
  }
`;

const LogoWrapper = styled.div`
  background-color: white;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 0 0;
  // menu styles

  /* regular menu item */
  & .item {
    color: ${primaryColors.paleGreen} !important;
    font-size: 16px;
  }
  // dropdown color
  & .ui.item.dropdown {
    color: ${primaryColors.paleGreen} !important;
  }
  .ui.menu .ui.dropdown .menu > .item:hover {
    color: ${primaryColors.paleGreen} !important;
  }

  .ui.menu .ui.dropdown .menu > .item {
    color: ${primaryColors.paleGreen} !important;
  }

  // general styles
  & .ui.secondary.pointing.menu {
    border-bottom: none !important;
    margin-top: 24px;
    .active.item {
      border-color: ${primaryColors.paleGreen};
    }
  }
`;

const Header = () => {
  const { setSidebarVisible, menuData } = useContext(AppContext);
  const isMobile = useMedia({ maxWidth: 1000 });
  const menuItems = menuData.allGoogleMenuPagesSheet.edges?.sort(
    (a, b) => a.position - b.position
  );
  const [activeItem, setActiveItem] = useState();
  const handleItemClick = (value) => {
    setActiveItem(value);
  };

  return (
    <>
      <Wrapper>
        <Link to="https://instagram.com/elsa_nextgen">
          <Icon name="instagram" />
        </Link>
        <span style={{ paddingLeft: "8px" }} />
        <Link to="https://www.linkedin.com/company/elsa-education-learning-skills-achievement">
          <Icon name="linkedin" />
        </Link>
        <span style={{ paddingLeft: "16px" }} />
        {!isMobile && (
          <Copy color="white" fontSize={22}>
            <a
              href="mailto:elsanextgen@gmail.com"
              style={{
                color: "white",
              }}
            >
              elsanextgen@gmail.com
            </a>{" "}
            |{" "}
            <a
              href="tel:07593 259312"
              style={{
                color: "white",
              }}
            >
              07593 259312
            </a>
          </Copy>
        )}
      </Wrapper>
      <LogoWrapper>
        <Link to={"/"}>
          <div
            style={{
              height: "100px",
            }}
          >
            <img
              src="https://start001.s3.us-east-2.amazonaws.com/ELSA_next_gen-01.png"
              height="100%"
              alt="elsa logo"
            />
          </div>
        </Link>
        {!isMobile && (
          <>
            <Menu pointing secondary>
              <Menu.Item
                as={Link}
                to={`/`}
                name="Home"
                active={activeItem === "home"}
                onClick={() => handleItemClick("/")}
                position={1}
              />
              {menuItems?.map(
                (
                  {
                    node: {
                      position,
                      slug,
                      title,
                      subMenu01Slug,
                      subMenu01Title,
                      subMenu02Slug,
                      subMenu02Title,
                      subMenu03Slug,
                      subMenu03Title,
                      subMenu04Slug,
                      subMenu04Title,
                      subMenu05Slug,
                      subMenu05Title,
                    },
                  },
                  i
                ) => (
                  <>
                    {subMenu01Slug ? (
                      <Dropdown item text={title}>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={`/${subMenu01Slug}`}
                            onClick={() => handleItemClick(subMenu01Slug)}
                          >
                            {subMenu01Title}
                          </Dropdown.Item>
                          {subMenu02Slug && (
                            <Dropdown.Item
                              as={Link}
                              to={`/${subMenu02Slug}`}
                              onClick={() => handleItemClick(subMenu02Slug)}
                            >
                              {subMenu02Title}
                            </Dropdown.Item>
                          )}
                          {subMenu03Slug && (
                            <Dropdown.Item
                              as={Link}
                              to={`/${subMenu03Slug}`}
                              onClick={() => handleItemClick(subMenu03Slug)}
                            >
                              {subMenu03Title}
                            </Dropdown.Item>
                          )}
                          {subMenu04Slug && (
                            <Dropdown.Item
                              as={Link}
                              to={`/${subMenu04Slug}`}
                              onClick={() => handleItemClick(subMenu04Slug)}
                            >
                              {subMenu04Title}
                            </Dropdown.Item>
                          )}
                          {subMenu05Slug && (
                            <Dropdown.Item
                              as={Link}
                              to={`/${subMenu05Slug}`}
                              onClick={() => handleItemClick(subMenu05Slug)}
                            >
                              {subMenu05Title}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <Menu.Item
                        as={Link}
                        to={`/${slug}`}
                        name={title}
                        active={activeItem === slug}
                        onClick={() => handleItemClick(slug)}
                        key={i}
                        position={i === 0 ? "right" : undefined}
                      />
                    )}
                  </>
                )
              )}
            </Menu>
          </>
        )}
        {isMobile && (
          <IconWrapper onClick={() => setSidebarVisible()}>
            <Icon name="bars" />
          </IconWrapper>
        )}
      </LogoWrapper>
    </>
  );
};

export default Header;
