import React, { useContext } from "react";
import styled from "styled-components";
import { Menu, Sidebar, Icon } from "semantic-ui-react";
import { AppContext } from "./layout";
import { primaryColors } from "../styles/colours";
import SliderMenuWrapper from "./menu";
import { Spacer } from "ui-start";

const Wrapper = styled.div`
  background-color: ${primaryColors.paleGreen};
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const CloseButtonWrapper = styled.div`
  position: fixed;
  top: 32px;
  left: 16px;
  i {
    font-size: 32px;
    color: white;
  }
`;

const SidebarContent = styled.div``;

export const SidebarWrapper = () => {
  const { sidebarVisible, setSidebarVisible } = useContext(AppContext);
  return (
    <Sidebar
      visible={sidebarVisible}
      as={Menu}
      width="wide"
      animation="slide along"
    >
      <Wrapper>
        <CloseButtonWrapper onClick={() => setSidebarVisible()}>
          <Icon name="close" />
        </CloseButtonWrapper>
        <SidebarContent>
          <Spacer height={80} />
          <SliderMenuWrapper />
        </SidebarContent>
      </Wrapper>
    </Sidebar>
  );
};
