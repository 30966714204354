import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { Container, Copy, Spacer } from "ui-start";
import { primaryColors } from "../styles/colours";
import { AppContext } from "./layout";

const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  a {
    color: white;
  }
  @media (max-width: 600px) {
    p {
      font-size: 16px;
      padding: 0 16px;
    }
  }
`;

export const Footer = () => {
  const { handleSetFooterHeight } = useContext(AppContext);
  const ref = useRef(null);

  useEffect(() => {
    handleSetFooterHeight(ref?.current?.offsetHeight);
  }, [handleSetFooterHeight]);

  return (
    <StyledFooter>
      <div ref={ref}>
        <Container
          width="100vw"
          backgroundColor={primaryColors.paleGreen}
          center
        >
          <>
            <Spacer height={24} />
            <Copy color="white" fontSize={22}>
              If you are a school, business or individual please contact us on
              the details below and we will be in touch.
            </Copy>
            <Copy color="white" fontSize={22}>
              <a
                href="mailto:elsanextgen@gmail.com"
                style={{
                  color: "white",
                }}
              >
                elsanextgen@gmail.com
              </a>{" "}
              |{" "}
              <a
                href="tel:07593 259312"
                style={{
                  color: "white",
                }}
              >
                07593 259312
              </a>
            </Copy>
            <Copy color="white" fontSize={18}>
              ELSA Next Generation is a Registered Community Interest Company
              No.12844533
            </Copy>
            <Spacer height={8} />
          </>
        </Container>
      </div>
    </StyledFooter>
  );
};
